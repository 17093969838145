import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgColor from 'components/svg-color';
import { NavListProps, NavSection } from 'components/nav-section/types';
import Iconify from 'components/iconify';

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  people: icon('eva:people-fill'),
};
const navGeneral: NavSection = {
  subheader: 'general',
  items: [
    {
      title: 'Trang chủ',
      path: PATH_DASHBOARD.general.app,
      icon: <Iconify icon="material-symbols:home-app-logo" />,
    },
    {
      title: 'Nạp tiền',
      path: PATH_DASHBOARD.general.banking,
      icon: <Iconify icon="ic:round-account-balance-wallet" />,
    },
    {
      title: 'Dịch vụ khác',
      path: PATH_DASHBOARD.general.anotherservice,
      icon: <Iconify icon="mdi:more" />,
    },
    {
      title: 'Lịch sử giao dịch',
      path: PATH_DASHBOARD.general.history,
      icon: <Iconify icon="ic:baseline-history" />,
    },
    {
      title: 'Tích hợp API',
      path: PATH_DASHBOARD.general.api,
      icon: <Iconify icon="ant-design:api-filled" />,
    },
    {
      title: 'Kênh hỗ trợ',
      path: PATH_DASHBOARD.general.support,
      icon: <Iconify icon="solar:help-outline" />,
    },
  ],
};

const itemAdmins = [
  {
    title: 'Quản lý Tài khoản',
    path: PATH_DASHBOARD.system.user,
    icon: <Iconify icon="fe:user" />,
  },
  {
    title: 'Quản lý Ngân hàng',
    path: PATH_DASHBOARD.system.bank,
    icon: <Iconify icon="fluent-mdl2:bank-solid" />,
  },
  {
    title: 'Quản lý Nhóm thành viên',
    path: PATH_DASHBOARD.system.loyal,
    icon: <Iconify icon="material-symbols:loyalty" />,
  },
  {
    title: 'Quản lý Khuyến mãi',
    path: PATH_DASHBOARD.system.topup,
    icon: <Iconify icon="nimbus:discount-circle" />,
  },
  {
    title: 'Quản lý Server',
    path: PATH_DASHBOARD.system.server,
    icon: <Iconify icon="basil:server-solid" />,
  },
  {
    title: 'Quản lý Dịch vụ',
    path: PATH_DASHBOARD.system.service,
    icon: <Iconify icon="material-symbols:home-repair-service" />,
  },
  {
    title: 'Quản lý Gói bán',
    path: PATH_DASHBOARD.system.pack,
    icon: <Iconify icon="ph:package-fill" />,
  },
  {
    title: 'Quản lý Bài viết',
    path: PATH_DASHBOARD.system.post,
    icon: <Iconify icon="material-symbols:post-add-rounded" />,
  },
  {
    title: 'Lịch sử',
    path: PATH_DASHBOARD.system.history.root,
    icon: <Iconify icon="ic:baseline-history" />,
  },
  {
    title: 'Báo cáo',
    path: PATH_DASHBOARD.system.report,
    icon: <Iconify icon="mdi:report-box" />,
  },
  {
    title: 'Cấu hình',
    path: PATH_DASHBOARD.system.config.root,
    icon: (
      <Iconify icon="streamline:computer-desktop-setting-settings-desktop-display-device-gear-cog-computer" />
    ),
  },
];

const getMenuAdmin = (permissions: { [x: string]: string[] }) => {
  const _items: NavListProps[] = [];

  Object.keys(permissions).forEach((p) => {
    const item = itemAdmins.find((f) => f.path.includes(p));
    if (item) _items.push(item);
  });
  const navAdmin: NavSection = {
    subheader: 'system',
    items: _items,
  };
  return navAdmin;
};

export { navGeneral, getMenuAdmin };
