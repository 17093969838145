import { useEffect, useState } from 'react';
// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import { useSettingsContext } from '../../components/settings';
//
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';
import DialogLogin from '../../sections/auth/DialogLogin';
import DialogError from 'components/DialogError';
import { clearError, setSocketMessage, setUser } from 'redux/slices/global';
import { useSelector } from 'redux/store';
import socket from 'utils/socket';
import { ISocketMessage } from '../../@types/global';
import MessageBox from 'components/MessageBox';
import { checkMe } from 'redux/slices/user';

// ----------------------------------------------------------------------

type Props = {
  children?: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');
  const { errors, user, socketMessage } = useSelector((state) => state.global);
  const [open, setOpen] = useState(false);

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  useEffect(() => {
    if (user) {
      socket.emit('join', user.id);
    } else {
      socket.emit('leave');
    }
    return () => {
      socket.emit('leave');
    };
  }, [user]);

  useEffect(() => {
    const loadMe = async () => {
      const user = await checkMe();
      setUser(user);
    };

    socket.on('message', (data?: ISocketMessage) => {
      setSocketMessage(data ?? null);
      loadMe();
    });

    return () => {
      socket.off();
    };
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  const renderContent = () => {
    if (isNavHorizontal) {
      return (
        <>
          <Header onOpenNav={handleOpen} />
          {isDesktop ? <NavHorizontal /> : renderNavVertical}
          <Main>{children}</Main>
          <DialogLogin />
          <MessageBox />
          <DialogError errors={errors} onClose={() => clearError()} />
        </>
      );
    }
    if (isNavMini) {
      return (
        <>
          <Header onOpenNav={handleOpen} />
          <Box
            sx={{
              display: { lg: 'flex' },
              minHeight: { lg: 1 },
            }}
          >
            {isDesktop ? <NavMini /> : renderNavVertical}
            <Main>{children}</Main>
          </Box>
          <DialogLogin />
          <MessageBox />
          <DialogError errors={errors} onClose={() => clearError()} />
        </>
      );
    }

    return (
      <>
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {renderNavVertical}

          <Main>{children}</Main>
        </Box>
        <DialogLogin />
        <MessageBox />
        <DialogError errors={errors} onClose={() => clearError()} />
      </>
    );
  };

  // return <AuthGuard> {renderContent()} </AuthGuard>;
  return <div>{renderContent()}</div>;
}
