import { useState } from 'react';
// next
import { useRouter } from 'next/router';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { useSnackbar } from '../../../components/snackbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from 'components/iconify';
import { useSelector } from 'redux/store';
import { onLogout } from 'redux/slices/global';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Cài đặt tài khoản',
    linkTo: '/user/account',
    icon: <Iconify icon="material-symbols:settings-account-box-outline-rounded" />,
  },
  {
    label: 'Lịch sử giao dịch',
    linkTo: '/history',
    icon: <Iconify icon="material-symbols:history-rounded" />,
  },
  {
    label: 'Điều khoản sử dụng',
    linkTo: '/terms',
    icon: <Iconify icon="mdi:court-hammer" />,
  },
  {
    label: 'Mở site cộng tác viên',
    linkTo: '/collaborators',
    icon: <Iconify icon="ic:round-read-more" />,
  },
  {
    label: 'Kênh hỗ trợ',
    linkTo: '/support',
    icon: <Iconify icon="ic:round-support" />,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { user } = useSelector((state) => state.global);
  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    const error = await onLogout();

    if (error) {
      enqueueSnackbar('Đã xảy ra lỗi!', { variant: 'error' });
    } else {
      router.reload();
      handleClosePopover();
    }
  };

  const handleClickItem = (path: string) => {
    handleClosePopover();
    router.push(path);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {/* <CustomAvatar src={user?.photoURL} alt={user?.displayName} name={user?.displayName} /> */}
        <CustomAvatar
          src={'/assets/images/avatars/avatar_default.jpg'}
          alt={user?.fullname}
          name={user?.fullname}
        />
      </IconButtonAnimate>
      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.fullname}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.icon}
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Đăng xuất
        </MenuItem>
      </MenuPopover>
    </>
  );
}
