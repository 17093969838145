import { useEffect, useState } from 'react';
// @mui
import { Box, Stack, Drawer, Button } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// config
import { NAV } from '../../../config';
// components
import Logo from 'components/logo';
import Scrollbar from 'components/scrollbar';
import { NavSectionVertical } from 'components/nav-section';
//
import { getMenuAdmin, navGeneral } from './myConfig';
import NavAccount from './NavAccount';
import { useSelector } from 'redux/store';
import { NavSection } from 'components/nav-section/types';
import Iconify from 'components/iconify';
import { TransferMenuToNav } from 'utils/func';
import { setShowLogin } from 'redux/slices/global';

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export const getIcon = (type: String) => {
  switch (type.toLowerCase()) {
    case 'facebook':
      return <Iconify icon="ri:facebook-box-fill" />;
    case 'tiktok':
      return <Iconify icon="simple-icons:tiktok" />;
    case 'instagram':
      return <Iconify icon="mdi:instagram" />;
    case 'twitter':
      return <Iconify icon="mdi:twitter" />;
    case 'telegram':
      return <Iconify icon="ic:baseline-telegram" />;
    case 'traffic':
      return <Iconify icon="material-symbols:traffic" />;
    case 'shopee':
      return <Iconify icon="tabler:brand-shopee" />;

    default:
      break;
  }
  return <Iconify icon="eva:github-fill" />;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { user } = useSelector((state) => state.global);
  const { menus } = useSelector((state) => state.service);

  const isDesktop = useResponsive('up', 'lg');

  const [dynamicConfig, setDynamicConfig] = useState<NavSection[]>([]);

  useEffect(() => {
    const allMenus = TransferMenuToNav(menus, getIcon);
    const Admin = user?.permission ? [getMenuAdmin(user.permission ?? {})] : [];
    setDynamicConfig([navGeneral, ...allMenus, ...Admin]);
  }, [menus, user]);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Logo />
        </Box>
        {user ? <NavAccount /> : <Button onClick={() => setShowLogin()}>Đăng nhập</Button>}
        {/*  */}
      </Stack>
      <NavSectionVertical data={dynamicConfig} />
      <Box sx={{ flexGrow: 1 }} />
      {/* <NavDocs /> */}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
