import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Card,
  Button,
  Box,
  Stack,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import AuthLoginForm from './AuthLoginForm';
import AuthRegisterForm from './AuthRegisterForm';
import { useSelector } from 'redux/store';
import { useState, useEffect } from 'react';
import { setShowLogin } from 'redux/slices/global';
import Image from 'next/image';
import Iconify from 'components/iconify/Iconify';

export default function DialogLogin() {
  const { showLogin, user } = useSelector((state) => state.global);
  const [currentTab, setCurrentTab] = useState<string>('LOGIN');

  const onClose = () => {
    setShowLogin(false);
  };

  useEffect(() => {
    setCurrentTab('LOGIN');
  }, [showLogin]);

  return (
    <Dialog fullWidth maxWidth="xs" open={showLogin && !user} onClose={onClose}>
      <DialogContent sx={{ px: 1, py: 5 }}>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Typography variant="h6">{currentTab === 'LOGIN' ? 'ĐĂNG NHẬP' : 'ĐĂNG KÝ'}</Typography>
        </Stack>
        <Stack spacing={2} sx={{ m: 5 }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{ mb: 4 }}
          >
            <Box sx={{ px: 2, pt: 1, borderRadius: 1, border: '1px solid #D8D8D8' }}>
              <Iconify icon="akar-icons:facebook-fill" width={30} height={30} color="#097DEA" />
            </Box>
            <Box sx={{ px: 2, pt: 1, borderRadius: 1, border: '1px solid #D8D8D8' }}>
              <Iconify icon="ion:logo-apple" width={30} height={30} />
            </Box>
            <Box sx={{ px: 2, pt: 1, borderRadius: 1, border: '1px solid #D8D8D8' }}>
              <Iconify icon="ri:google-fill" width={30} height={30} color="#EA4235" />
            </Box>
          </Stack>

          {currentTab === 'LOGIN' ? <AuthLoginForm /> : <AuthRegisterForm />}
          <Divider> Hoặc </Divider>
          {currentTab !== 'LOGIN' ? (
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => {
                setCurrentTab('LOGIN');
              }}
              fullWidth
            >
              Đăng nhập
            </Button>
          ) : (
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                setCurrentTab('REGISTER');
              }}
              fullWidth
              color="success"
            >
              Đăng ký
            </Button>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
