import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { IErrorForlikeResponse } from '../@types/error';
import { DialogAnimate } from './animate';
import Iconify from './iconify/Iconify';

interface IProps {
  errors: IErrorForlikeResponse[];
  onClose: () => void;
}

export default function DialogError({ errors, onClose }: IProps) {
  const theme = useTheme();
  return (
    <DialogAnimate open={errors.length > 0} onClose={onClose}>
      <DialogTitle sx={{ bgcolor: theme.palette.error.lighter }}>Thông báo!</DialogTitle>
      <DialogContent sx={{ m: 2 }}>
        <Stack direction="column" alignItems="flex-start" justifyContent="center">
          {errors.map((m, index) => (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              key={index}
              spacing={2}
            >
              <Iconify icon="ic:round-error-outline" color={theme.palette.error.main} />
              <Typography color={theme.palette.error.main} variant="body2">
                {m.message}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="small" color="primary" variant="outlined" onClick={onClose}>
          Đóng
        </Button>
      </DialogActions>
    </DialogAnimate>
  );
}
