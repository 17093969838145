// @mui
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  DialogActions,
} from '@mui/material';
// ----------------------------------------------------------------------
import { useEffect, useState } from 'react';

// components
import Iconify from 'components/iconify';
import { useSelector } from 'redux/store';
import ItemContent from 'sections/@dashboard/app/ItemContent';
import { getCachePostId, setCachePostId } from 'utils/func';
import _ from 'lodash';

export default function NotificationView({ SiteName }: { SiteName: string }) {
  const { user, notifications } = useSelector((state) => state.global);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const ids = getCachePostId();
    const isEqual = _.isEqual(
      ids,
      notifications.map((m) => m.id)
    );
    if (isEqual !== true) setIsShow(true);
  }, [notifications]);

  const handleCloseNotification = () => {
    setCachePostId(notifications.map((m) => m.id));
    setIsShow(false);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isShow} onClose={handleCloseNotification}>
      <DialogTitle sx={{ pb: 2 }}>
        <Box style={{ alignItems: 'center', display: 'flex' }}>
          <Iconify icon="eva:bell-fill" />
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Thông báo
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {notifications.map((item) => (
          <ItemContent
            key={item.id}
            post={item}
            user={user}
            onDelete={() => {}}
            SiteName={SiteName}
          />
        ))}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleCloseNotification}>
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  );
}
