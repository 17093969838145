// @mui
import { Stack, Box } from '@mui/material';
// config
import { NAV } from '../../../config';
// utils
import { hideScrollbarX } from '../../../utils/cssStyles';
// components
import Logo from '../../../components/logo';
import { NavSectionMini } from '../../../components/nav-section';
import { useSelector } from 'redux/store';
import { useEffect, useState } from 'react';
import { NavSection } from 'components/nav-section/types';
// ----------------------------------------------------------------------
import { getMenuAdmin, navGeneral } from './myConfig';
import { getIcon } from './NavVertical';
import { TransferMenuToNav } from 'utils/func';

export default function NavMini() {
  const { user } = useSelector((state) => state.global);
  const { menus } = useSelector((state) => state.service);
  const [dynamicConfig, setDynamicConfig] = useState<NavSection[]>([]);

  useEffect(() => {
    const allMenus = TransferMenuToNav(menus, getIcon);
    const Admin = user?.permission ? [getMenuAdmin(user.permission ?? {})] : [];
    setDynamicConfig([navGeneral, ...allMenus, ...Admin]);
  }, [menus, user]);

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
      }}
    >
      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        <Logo sx={{ mx: 'auto', my: 2 }} />

        <NavSectionMini data={dynamicConfig} />
      </Stack>
    </Box>
  );
}
