import {
  Card,
  Typography,
  Stack,
  Divider,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  ImageList,
  ImageListItem,
  Tooltip,
  Avatar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
// hooks
import { fToNow } from 'utils/formatTime';
import { RandomNumber, fNumber } from 'utils/formatNumber';
import { IPost } from '../../../@types/post';
import Iconify from 'components/iconify/Iconify';
import { AuthUserType } from '../../../@types/global';
import { useEffect, useState } from 'react';
import Markdown from 'components/markdown/Markdown';
import { setCurrentPost, setOpenDialogForm } from 'redux/slices/post';
import uuidv4 from 'utils/uuidv4';
import Image from 'components/image/Image';
//- ------------------------------------

type ItemContentProp = {
  SiteName: string;
  post: IPost;
  user?: AuthUserType;
  onDelete: (post: IPost) => void;
};

export default function ItemContent({ post, user, onDelete, SiteName }: ItemContentProp) {
  const theme = useTheme();
  const [numLike, setNumlike] = useState([0, 0]);

  useEffect(() => {
    setNumlike([RandomNumber(), RandomNumber()]);
  }, []);

  return (
    <Card sx={{ p: 2 }}>
      <Stack spacing={0}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            <Avatar
              alt={SiteName}
              src={'/favicon/icon/128.png'}
              sx={{
                ...theme.typography.caption,
                // margin: '8px 8px 8px 8px !important',
                cursor: 'pointer',
                p: '5px',
                border: 'solid 1px #1B5AA9',
              }}
              aria-controls="menu-list-grow"
              aria-haspopup="true"
              color="inherit"
            />
            <Stack>
              <Typography variant="body1">{SiteName}</Typography>
              <Typography variant="subtitle2">{fToNow(post.createdAt)}</Typography>
            </Stack>
          </Stack>
          {!user?.permission ? null : (
            <Stack direction="row">
              <IconButton
                color="primary"
                onClick={() => {
                  setCurrentPost(post);
                  setOpenDialogForm(true);
                }}
              >
                <Tooltip title="Sửa bài viết">
                  <Iconify icon="material-symbols:edit-document-rounded" />
                </Tooltip>
              </IconButton>
              <IconButton color="error" onClick={() => onDelete(post)}>
                <Tooltip title="Xóa bài viết">
                  <Iconify icon="ic:round-delete" />
                </Tooltip>
              </IconButton>
            </Stack>
          )}
        </Stack>
        <Stack spacing={1}>
          <Markdown children={post.content} />
        </Stack>
        <ImageList
          sx={{ width: '100%' }}
          variant="masonry"
          cols={post.filePaths.length < 2 ? post.filePaths.length : 2}
          gap={8}
        >
          {post.filePaths.map((item, index) => (
            <ImageListItem key={uuidv4()}>
              <Image
                src={`/api/v1/post/image/${item}?w=248&fit=crop&auto=format`}
                srcSet={`/api/v1/post/image/${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={SiteName}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <Stack direction="row">
            <Iconify icon="mingcute:thumb-up-2-fill" color="#0193F2" />
            <Iconify icon="material-symbols:favorite-rounded" color="#FA5D76" />
            <Typography variant="body2">{`Admin và ${fNumber(numLike[0])} người khác`}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography variant="body2">{`${fNumber(numLike[1])} Bình luận`}</Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Button fullWidth startIcon={<Iconify icon="mingcute:thumb-up-2-fill" />}>
            Thích
          </Button>
          <Button
            fullWidth
            startIcon={<Iconify icon="heroicons:chat-bubble-left-ellipsis-solid" />}
          >
            Bình luận
          </Button>
          <Button fullWidth startIcon={<Iconify icon="ic:sharp-screen-share" />}>
            Chia sẻ
          </Button>
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <Avatar
            alt={SiteName}
            src={'/favicon/icon/128.png'}
            sx={{
              ...theme.typography.caption,
              margin: '8px 8px 8px 8px !important',
              cursor: 'pointer',
              p: '5px',
              border: 'solid 1px #1B5AA9',
            }}
            aria-controls="menu-list-grow"
            aria-haspopup="true"
            color="inherit"
          />
          <TextField
            fullWidth
            size="small"
            placeholder="Viết bình luận..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton title="gửi bình luận" color="primary">
                    <Iconify icon="material-symbols:send-rounded" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </Stack>
    </Card>
  );
}
