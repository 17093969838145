import { useState } from 'react';
// components
import Image from '../../../components/image';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Card,
  Typography,
  Link,
  Button,
  Avatar,
  Divider,
  Stack,
  Box,
  Grid,
} from '@mui/material';
// ----------------------------------------------------------------------
import { alpha, styled } from '@mui/material/styles';
import SvgColor from 'components/svg-color';
import { IConfig } from '../../../@types/config';
import { ItemSupportValue } from 'pages/support';
import { ConfigKey } from 'utils/constant';

const StyledOverlay = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[900], 0.64),
}));

export default function CustomHeaderCenter({ configs }: { configs: IConfig[] }) {
  const [isShow, setIsShow] = useState(false);

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
      <Button
        variant="outlined"
        onClick={() => {
          setIsShow(true);
        }}
      >
        Dịch vụ khác
      </Button>

      <Dialog
        fullWidth
        maxWidth="md"
        open={isShow}
        onClose={() => {
          setIsShow(false);
        }}
        style={{ marginBottom: 100 }}
      >
        <DialogTitle sx={{ pb: 2 }}>Chọn dịch vụ</DialogTitle>
        <DialogContent>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
            }}
          >
            {configs
              .filter((f) => f.name === ConfigKey.OtherService)
              .map((item, index) => {
                let content: ItemSupportValue | null = null;
                try {
                  content = JSON.parse(item.value);
                } catch (e) {
                  //
                }
                if (!content) return null;

                return (
                  <Grid key={content.title} item xs={12} md={4}>
                    <Link
                      rel="noopener noreferrer"
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      underline="none"
                      variant="caption"
                      sx={{
                        cursor: 'pointer',
                        color: 'text.secondary',
                        transition: (theme) => theme.transitions.create('all'),
                        '&:hover': { color: 'primary.main' },
                      }}
                      href={content.link}
                      target="_blank"
                    >
                      <Card style={{ textAlign: 'center' }}>
                        <Box sx={{ position: 'relative' }}>
                          <SvgColor
                            src="/assets/shape_avatar.svg"
                            sx={{
                              width: 144,
                              height: 62,
                              zIndex: 10,
                              left: 0,
                              right: 0,
                              bottom: -26,
                              mx: 'auto',
                              position: 'absolute',
                              color: 'background.paper',
                            }}
                          />

                          <Avatar
                            alt={'name'}
                            src={content.linkIcon}
                            sx={{
                              width: 64,
                              height: 64,
                              zIndex: 11,
                              left: 0,
                              right: 0,
                              bottom: -32,
                              mx: 'auto',
                              position: 'absolute',
                            }}
                          />

                          <StyledOverlay />

                          <Image src={content.linkBackGround} alt={'cover'} ratio="16/9" />
                        </Box>

                        <Typography variant="subtitle1" sx={{ mt: 6, mb: 0.5 }}>
                          {content.title}
                        </Typography>

                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ mt: 1, mb: 3 }}
                        ></Stack>

                        <Divider sx={{ borderStyle: 'dashed' }} />
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.secondary', mt: 2, pl: 1, pr: 1 }}
                        >
                          {content.content}
                        </Typography>
                        <Button sx={{ mt: 1, mb: 1 }} variant="outlined">
                          Truy cập ngay
                        </Button>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ mt: 1, mb: 3 }}
                        ></Stack>
                      </Card>
                    </Link>
                  </Grid>
                );
              })}
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </Box>
  );
}
