import { HOST_API_SOCKET } from 'config';
import { io } from 'socket.io-client';

const serverUrl = HOST_API_SOCKET;
// Đường dẫn đích (path) bạn muốn kết nối tới
const targetPath = '/ws';

const isServer = typeof window === 'undefined';

export const socket = io(serverUrl, {
  path: targetPath,
  transports: ['websocket', 'polling'],
  auth: {
    token: isServer ? '' : localStorage.getItem('accessToken'),
  },
});

export default socket;
