import { forwardRef } from 'react';
// next
import NextLink from 'next/link';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps, Stack } from '@mui/material';
import Image from 'components/image/Image';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          display: 'inline-flex',
          maxWidth: 120,
          ...sx,
        }}
        {...other}
      >
        <Image src={'/favicon/logo.png'} />
      </Box>
    );

    if (disabledLink) {
      return <>{logo}</>;
    }

    return (
      <NextLink href="/" passHref>
        <Stack sx={{ display: 'contents' }}>{logo}</Stack>
      </NextLink>
    );
  }
);

export default Logo;
