import numeral from 'numeral';
import {
  InvalidFormatError,
  InvalidNumberError,
  ReadingConfig,
  UnitNotEnoughError,
  parseNumberData,
  readNumber,
  validateNumber,
} from 'read-vietnamese-number/dist';

// ----------------------------------------------------------------------

type InputValue = string | number | null;

export function fNumber(number: InputValue) {
  const format = number ? numeral(number).format('0,0.00') : '0';
  return result(format, '.00');
}

export function fNumberFix(number: InputValue) {
  return numeral(number).format();
}

export function fCurrency(number: InputValue) {
  const format = number ? numeral(number).format('$0,0.00') : '0';

  return result(format, '.00');
}

export function fPercent(number: InputValue) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '0';

  return result(format, '.0');
}

export function fShortenNumber(number: InputValue) {
  const format = number ? numeral(number).format('0.00a') : '0';

  return result(format, '.00');
}

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : '0';

  return result(format, '.0');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function RandomNumber() {
  return Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000;
}

export const convertNumberToText = (data: number) => {
  const config = new ReadingConfig();
  config.unit = ['đồng'];

  try {
    const validatedNumber = validateNumber(data); // Step 2
    const numberData = parseNumberData(config, validatedNumber); // Step 3
    const result = readNumber(config, numberData); // Step 4
    return result;
  } catch (err) {
    if (err instanceof InvalidFormatError) {
      return 'Định dạng input không hợp lệ';
    } else if (err instanceof InvalidNumberError) {
      return 'Số không hợp lệ';
    } else if (err instanceof UnitNotEnoughError) {
      return 'Không đủ đơn vị đọc số';
    }
    return 'Lỗi không xác định';
  }
};
