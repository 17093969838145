import { useEffect, useState } from 'react';
import * as Yup from 'yup';
// form
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Alert, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../components/iconify';
import FormProvider, { RHFTextField } from '../../components/hook-form';
import { onRegister } from 'redux/slices/global';
import { IErrorForlikeResponse } from '../../@types/error';
import { useRouter } from 'next/router';

// ----------------------------------------------------------------------

type FormValuesProps = {
  userName: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  afterSubmit?: string;
};

export default function AuthRegisterForm() {
  const router = useRouter();
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState<IErrorForlikeResponse[]>([]);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('Thông tin bắt buộc'),
    userName: Yup.string().required('Thông tin bắt buộc'),
    lastName: Yup.string().required('Thông tin bắt buộc'),
    email: Yup.string().email('Vui lòng kiểm tra lại định dạng').required('Thông tin bắt buộc'),
    password: Yup.string().required('Thông tin bắt buộc'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    userName: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const email = useWatch({ control, name: 'email' });
  const userName = useWatch({ control, name: 'userName' });

  useEffect(() => {
    if (email) {
      setValue('email', email.toLowerCase());
    }
    if (userName) {
      setValue('userName', userName.toLowerCase());
    }
  }, [email, userName, setValue]);

  const onSubmit = async (data: FormValuesProps) => {
    const error = await onRegister(
      data.email,
      data.password,
      data.firstName,
      data.lastName,
      data.userName
    );
    if (error) {
      setErrors(error.errors);
    } else {
      router.reload();
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5}>
        {errors.map((error, index) => (
          <FormHelperText error key={index}>
            {error.message}
          </FormHelperText>
        ))}
        <RHFTextField name="userName" label="Tên tài khoản" size="small" />
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="Họ" size="small" />
          <RHFTextField name="lastName" label="Tên" size="small" />
        </Stack>

        <RHFTextField name="email" label="Địa chỉ email" size="small" />

        <RHFTextField
          name="password"
          label="Mật khẩu"
          size="small"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          color="success"
          size="small"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Đăng ký
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
