import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { DialogAnimate } from './animate';
import Iconify from './iconify/Iconify';
import { useSelector } from 'redux/store';
import { clearSocketMessage } from 'redux/slices/global';

export default function MessageBox() {
  const theme = useTheme();
  const { socketMessage } = useSelector((state) => state.global);

  const handleClose = () => {
    clearSocketMessage();
  };

  return (
    <DialogAnimate open={!!socketMessage} onClose={handleClose}>
      <DialogTitle sx={{ bgcolor: theme.palette.error.lighter }}>Thông báo!</DialogTitle>
      <DialogContent sx={{ m: 2 }}>
        <Stack direction="column" alignItems="flex-start" justifyContent="center">
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
            {socketMessage?.variant === 'success' ? (
              <Iconify icon="ic:round-error-outline" color={theme.palette.primary.main} />
            ) : (
              <Iconify icon="ic:round-error-outline" color={theme.palette.error.main} />
            )}
            <Typography
              color={
                socketMessage?.variant === 'success'
                  ? theme.palette.primary.main
                  : theme.palette.error.main
              }
              variant="body2"
            >
              {socketMessage?.content ?? ''}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="small" color="primary" variant="outlined" onClick={handleClose}>
          Đóng
        </Button>
      </DialogActions>
    </DialogAnimate>
  );
}
